import React, { useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import { addDays, differenceInDays, isBefore } from 'date-fns';
import SVG from 'components/svg';
import type { Application } from 'data/get-applications';
import { useApplications } from 'hooks/use-applications';
import { APPLICATION_STATUS } from 'types/ApplicationStatus';
import { getColorForLabel } from 'utils/color';

type ApplicationByDate = {
  date: string,
  qty: number
}

const CHART_WIDTH = 800;
const CHART_HEIGHT = 450;

enum margin {
  'Top' = 16,
  'Right' = 16,
  'Bottom' = 32,
  'Left' = 32,
}

const ApplicationDateBar = ({d, width, x, y}: {d: ApplicationByDate, width: number, x: number, y: number}) => {
  const h = useMemo(() => {
    return (CHART_HEIGHT - margin.Top - margin.Bottom) / 20 * d.qty;
  }, [d.qty]);

  return (
    <g>
      <rect x={x} y={y} width={width} height={h} fill={getColorForLabel(APPLICATION_STATUS.Applied)}/>
      <text x={x + (width / 2)} y={y} dy="-3" textAnchor="middle">
        {d.qty}
      </text>
    </g>
  );
}

const ApplicationsSent = () => {
  const applications: Application[] = useApplications();
  const chartRef = useRef<SVGSVGElement>(null);

  const applicationsByDate = useMemo(() => {
    if (!applications) return [];
    return applications
      .filter((app: Application) => app?.Applied)
      .reduce<ApplicationByDate[]>((acc: ApplicationByDate[], app: Application) => {
      if (app?.Applied) {
        const currDate = acc.find(d => d.date === app.Applied);
        if (currDate) {
          currDate.qty++;
        } else {
          acc.push({
            date: app.Applied,
            qty: 1
          });
        }
      }
      return acc;
    }, []);
  }, [applications]);

  const startDate = useMemo(() => applicationsByDate.sort(
    (a: ApplicationByDate, b: ApplicationByDate) => isBefore(a?.date, b?.date) ? -1 : 1
  )[0]?.date, [applicationsByDate]);

  const endDate = useMemo(() => applicationsByDate.sort(
    (a: ApplicationByDate, b: ApplicationByDate) => isBefore(a?.date, b?.date) ? 1 : -1
  )[0]?.date, [applicationsByDate]);

  const totalDays = useMemo(() => Math.abs(differenceInDays(startDate, endDate)), [startDate, endDate]);

  const barWidth = useMemo(() => ((CHART_WIDTH - margin.Left - margin.Right) / totalDays) - 2, [totalDays]);

  const xScale = useMemo(() => d3.scaleUtc()
    .domain([
      new Date(addDays(startDate, -1)),
      new Date(addDays(endDate, 1)),
    ])
    .range([margin.Left, CHART_WIDTH - margin.Right]), [startDate, endDate]);


  const yScale = d3.scaleLinear()
    .domain([0, 20])
    .range([CHART_HEIGHT - margin.Bottom, margin.Top]);

  useEffect(() => {
    if (!chartRef?.current || !applicationsByDate.length) return;

    const svg = d3.select(chartRef.current);


    const xAxis = svg.select('#axis-x');
    const yAxis = svg.select('#axis-y');

    if (xAxis) {
      xAxis
        .call(d3.axisBottom(xScale) as any);
    }
    if (yAxis) {
      yAxis
        .call(d3.axisLeft(yScale) as any);
    }
  }, [applicationsByDate, startDate, endDate, totalDays, xScale, yScale]);

  return (
   <>
    <h2>Applications Sent</h2>
    <SVG ref={chartRef} width={CHART_WIDTH} height={CHART_HEIGHT} shouldRender={!!applications}>
      <g id="axis-x" transform={`translate(0, ${CHART_HEIGHT - margin.Bottom})`} />
      <g id="axis-y" transform={`translate(${margin.Left}, 0)`} />
      <g className="data">
        {applicationsByDate.map((d: ApplicationByDate) => (
          <ApplicationDateBar
            key={d.date}
            width={barWidth}
            d={d}
            x={xScale(new Date(d.date)) - barWidth / 2}
            y={(CHART_HEIGHT - margin.Bottom - 1) - ((CHART_HEIGHT - margin.Top - margin.Bottom) / 20 * d.qty)}
          />
        ))}
      </g>
    </SVG>
   </>
  );
};

export default ApplicationsSent;
