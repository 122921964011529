import React, { useMemo, useRef } from 'react';
import * as d3sankey from 'd3-sankey';
import SVG from 'components/svg';
import type { Application } from 'data/get-applications';
import { useApplications } from 'hooks/use-applications';
import { APPLICATION_STATUS, APPLICATION_STATUS_LABELS } from 'types/ApplicationStatus';
import { getColorForLabel } from 'utils/color';

interface INode {
  node: number;
  name: string;
  value?: number;
  x0?: number;
  x1?: number;
  y0?: number;
  y1?: number;
}

interface ILink {
  source: number | INode | any;
  target: number | INode | any;
  value: number;
  x0?: number;
  x1?: number;
  y0?: number;
  y1?: number;
}

const CHART_WIDTH = 800;
const CHART_HEIGHT = 450;
const CHART_PADDING = 32;

let nodes: d3sankey.SankeyNode<INode, ILink>[] = [];
Object.entries(APPLICATION_STATUS).filter(([key, status]) => status !== 'All')
  .forEach(([key, status], idx) => {
  const overrideStatus = [APPLICATION_STATUS.Technical, APPLICATION_STATUS.Code].includes(status)
    ? APPLICATION_STATUS.Interviews
    : status;

    if (nodes.find(n => n.name === overrideStatus) === undefined) {
    nodes.push({
      name: overrideStatus,
      node: idx
    });
  }
});

const getNodeIndex = (nodeName?: APPLICATION_STATUS | string): number => {
  return nodeName ? nodes.findIndex(n => n.name === nodeName) || 0 : 0;
}

const getLinksFromApplications = (applications: Application[]): ILink[] => {
  let links: d3sankey.SankeyLink<INode, ILink>[] = [];

  const updateLink = (sourceName: APPLICATION_STATUS, targetName: APPLICATION_STATUS, override?: number) => {
    const source = getNodeIndex(sourceName);
    const target = getNodeIndex(targetName);
    const thisLink = links.find(l => l.source === source && l.target === target);

    if (!thisLink) {
      links.push({
        source,
        target,
        value: override ?? 1
      });
    } else {
      thisLink.value++;
    }
  }

  applications
    .filter((app: Application) => app?.[APPLICATION_STATUS.Applied])
    .forEach((app: Application) => {
      let latestStatus: APPLICATION_STATUS = APPLICATION_STATUS.Applied;

      if (app?.Origin) {
        updateLink(app?.Origin as APPLICATION_STATUS, APPLICATION_STATUS.Applied);
      }

      if (app?.[APPLICATION_STATUS.Initial]) {
        updateLink(APPLICATION_STATUS.Applied, APPLICATION_STATUS.Initial);
        latestStatus = APPLICATION_STATUS.Initial;

        if (app?.[APPLICATION_STATUS.MgrTeam]) {
          updateLink(latestStatus, APPLICATION_STATUS.MgrTeam);
          latestStatus = APPLICATION_STATUS.MgrTeam;
        }

        if (app?.[APPLICATION_STATUS.Technical] || app?.[APPLICATION_STATUS.Code]) {
          updateLink(latestStatus, APPLICATION_STATUS.Interviews);
          latestStatus = APPLICATION_STATUS.Interviews;
        }

        if (app?.[APPLICATION_STATUS.Offer]) {
          updateLink(latestStatus, APPLICATION_STATUS.Offer);
          latestStatus = APPLICATION_STATUS.Offer;
        }

        if (app?.Result === APPLICATION_STATUS.Accepted) {
          updateLink(latestStatus, APPLICATION_STATUS.Accepted);
          latestStatus = APPLICATION_STATUS.Accepted;
        } else if (app?.[APPLICATION_STATUS.Rejected] || app?.[APPLICATION_STATUS.Rejected]) {
          updateLink(latestStatus, APPLICATION_STATUS.Rejected);
        } else if (app.Result === APPLICATION_STATUS.Declined) {
          updateLink(latestStatus, APPLICATION_STATUS.Declined);
        } else if (!app?.[APPLICATION_STATUS.Offer]) {
          updateLink(latestStatus, APPLICATION_STATUS.Waiting);
        }
      } else if (app?.[APPLICATION_STATUS.Rejected] || app.Result === APPLICATION_STATUS.Rejected) {
        updateLink(APPLICATION_STATUS.Applied, APPLICATION_STATUS.Rejected);
      } else if (app.Result === APPLICATION_STATUS.Declined) {
        updateLink(APPLICATION_STATUS.Applied, APPLICATION_STATUS.Declined);
      } else {
        updateLink(APPLICATION_STATUS.Applied, APPLICATION_STATUS.Waiting);
      }
    });

  return links;
}

const GraphGradient = ({link}: {link: d3sankey.SankeyLink<INode, ILink>}) => {
  const { source, target } = link;
  const id = `${source.node}-${target.node}`
  return (
    <linearGradient id={id}>
      <stop offset="0%" stopColor={getColorForLabel(source.name)} />
      <stop offset="100%" stopColor={getColorForLabel(target.name)} />
    </linearGradient>
  );
}

const GraphLink = ({link}: {link: d3sankey.SankeyLink<INode, ILink>}) => {
  const { source, target, value, width, y0 = 0 } = link;
  if (!source || !target) return null;
  const gradientId = `url(#${source.node}-${target.node})`;

  return (
    <>
      <path
        className="link"
        d={d3sankey.sankeyLinkHorizontal()(link) as string}
        stroke={gradientId || getColorForLabel(target.name)}
        strokeWidth={width || 0}
        fill="none"
      />
      {value && <text className="link-description" textAnchor="start" x={source.x1} y={y0} dx="4" dy=".33em">{value}</text>}
    </>
  )
}

const GraphNode = ({node, width}: {node: any, width: number}) => {
  let { x0, x1, y0, y1, name, value } = node;

  if (!x0 || !x1 || !y0 || !y1) return null;

  if (value === 0) {
    y0 = CHART_HEIGHT - CHART_PADDING;
    y1 = CHART_HEIGHT - CHART_PADDING;
  }

  return (
    <g>
      <rect
        className="node"
        x={x0}
        y={y0}
        width={width}
        height={Math.abs(y1 - y0)}
        fill={getColorForLabel(name)}
      />
      <text
        x={x1 - 4}
        y={y0 + (y1 - y0) / 2}
        dy="-3"
        textAnchor="end"
      >{APPLICATION_STATUS_LABELS?.[name] || name}</text>
      <text
        x={x1 - 4}
        y={y0 + (y1 - y0) / 2}
        dy="9"
        textAnchor="end"
      >({value})</text>
    </g>
  )
}

const ApplicationsFlow = () => {
  const applications: Application[] = useApplications();
  const chartRef = useRef<SVGSVGElement>(null);

  const chart = d3sankey
    .sankey()
    .nodeWidth(CHART_PADDING)
    .nodePadding(16)
    .extent([[CHART_PADDING, CHART_PADDING], [CHART_WIDTH - CHART_PADDING, CHART_HEIGHT - CHART_PADDING]]);

  const applicationsChart = useMemo((): d3sankey.SankeyGraph<{}, {}> | undefined => {
    if (!applications) return undefined;

    const links = getLinksFromApplications(applications);

    return chart({
        nodes,
        links
      });
  }, [applications, chart]);

  return (
    <>
      <h2>All Applications</h2>
      <SVG ref={chartRef} width={CHART_WIDTH} height={CHART_HEIGHT} shouldRender={!!applications}>
        <defs>
          {applicationsChart?.links.map((link: any) => <GraphGradient key={`fg-${link.index}`} link={link} />)}
        </defs>
        <g className="data">
          {applicationsChart?.links.map((link: any) => <GraphLink key={`fl-${link.index}`} link={link} />)}
          {applicationsChart?.nodes.map((node: any) => <GraphNode key={`fn-${node.index}`} node={node} width={chart.nodeWidth()} />)}
        </g>
      </SVG>
    </>
   );
}

export default ApplicationsFlow;
