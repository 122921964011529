import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import { differenceInBusinessDays } from 'date-fns/differenceInBusinessDays'
import SVG from 'components/svg';
import type { Application } from 'data/get-applications';
import { useApplications } from 'hooks/use-applications';
import { APPLICATION_STATUS, APPLICATION_STATUS_LABELS, APPLICATION_STATUS_LABELS_SHORTENED, Source } from 'types/ApplicationStatus';
import { getColorForLabel } from 'utils/color';

type StatusDuration = {
  status: string,
  start: APPLICATION_STATUS,
  end: APPLICATION_STATUS,
  times: number[],
}

const CHART_WIDTH = 800;
const CHART_HEIGHT = 450;

enum margin {
  'Top' = 16,
  'Right' = 16,
  'Bottom' = 32,
  'Left' = 32,
}

const FILTER_VALUES = [
  APPLICATION_STATUS.All,
  APPLICATION_STATUS.Me,
  APPLICATION_STATUS.Recruiter
];

const statusesToShow = [
  APPLICATION_STATUS.Initial,
  APPLICATION_STATUS.MgrTeam,
  APPLICATION_STATUS.Interviews,
  APPLICATION_STATUS.Offer,
  APPLICATION_STATUS.Rejected,
  APPLICATION_STATUS.Waiting,
];

const avg = (numbers: number[]): number => Math.ceil(numbers.reduce((acc, curr) => acc + curr, 0) / numbers.length) || 0;

const gradientId = (start: APPLICATION_STATUS, end?: APPLICATION_STATUS): string => {
  return [start, end ?? 'end'].map((str) => str.toLowerCase().replaceAll(' ', '_')).join('-');
}

const Gradient = ({start, end}: {start: APPLICATION_STATUS, end?: APPLICATION_STATUS}) => {
  const gradientProps = end
    ? {}
    : {
      x1: '0%',
      x2: '0%',
      y1: '100%',
      y2: '0%',
    };
  return (
    <linearGradient id={gradientId(start, end)} {...gradientProps}>
      <stop offset="0%" stopColor={getColorForLabel(start)} />
      {end
        ? <stop offset="100%" stopColor={getColorForLabel(end)} />
        : <stop offset="75%" stopColor={getColorForLabel(start)} stopOpacity="0" />
      }
    </linearGradient>
  )
}

const Bar = ({
  d,
  width,
  x,
  yScale,
  filter
}: {
  d: StatusDuration,
  width: number,
  x: number,
  yScale: d3.ScaleLinear<number, number, never>,
  filter?: APPLICATION_STATUS
}) => {
  if (!filter) filter = APPLICATION_STATUS.All;

  const { start, end, times } = d;
  const days = avg(times);
  const baseline = CHART_HEIGHT - (margin.Top + margin.Bottom);
  const h = useMemo(() => CHART_HEIGHT - margin.Bottom - yScale(days), [days, yScale]);
  const y = baseline - h - 1;
  const subject = filter === APPLICATION_STATUS.Me ? 'I' : 'a recruiter';
  const prefixClause = filter === APPLICATION_STATUS.All
    ? 'On average'
    : `On average, when ${subject} initiated an application`
  const startLabel = APPLICATION_STATUS_LABELS[start] ?? start;
  const endLabel = APPLICATION_STATUS_LABELS[end] ?? end;
  const title = `${prefixClause}, it took ${days} days to move from ${startLabel} to ${endLabel}.`;
  const fillIdStatus = gradientId(start, end);
  const fillIdOrigin = gradientId(filter);

  return (
    <g>
      <title>{title}</title>
      <rect x={x} y={y} width={width / 2} height={h} fill={`url(#${fillIdStatus})`} />
      <rect x={x} y={y} width={width / 2} height={h} fill={`url(#${fillIdOrigin})`} />
      <text x={x + width / 4} y={baseline - 3} textAnchor="middle">
        {days ? `${days}d` : 'N/A'}
      </text>
    </g>
  );
}

const ApplicationsTiming = () => {
  const applications: Application[] = useApplications();
  const chartRef = useRef<SVGSVGElement>(null);
  const [filterValue, setFilterValue] = useState<APPLICATION_STATUS>(APPLICATION_STATUS.All);

  const statusDurations = useMemo(() => {
    if (!applications) return [];

    const filteredApplications = applications.filter((a: Application) => {
      if (filterValue === 'All') {
        return true;
      }

      return a.Origin === filterValue;
    })

    const data: StatusDuration[] = [];

    const updateStatus = (
      statuses: [APPLICATION_STATUS, APPLICATION_STATUS],
      source: Source,
      start: Date | string,
      end: Date | string,
    ) => {
      const [s, t] = statuses;

      if (!start || !end) {
        return;
      }

      const days = Math.abs(differenceInBusinessDays(start, end));

      const sLabel = APPLICATION_STATUS_LABELS_SHORTENED?.[s] || s;
      const tLabel = APPLICATION_STATUS_LABELS_SHORTENED?.[t] || t;

      const key = `${sLabel.split(' ')[0]} → ${tLabel.split(' ')[0]}`;

      const dataStatus = data.find(d => d.status === key);
      if (!dataStatus) {
        data.push({
          status: key,
          start: s,
          end: t,
          times: [days],
        })
      } else {
        dataStatus.times.push(days);
      }
    }

    filteredApplications
      .filter((app: Application) => app?.[APPLICATION_STATUS.Applied])
      .forEach((app: Application) => {
        let latestStatus: APPLICATION_STATUS = APPLICATION_STATUS.Applied;

        if (!app?.Origin) return;

        if (app?.[APPLICATION_STATUS.Initial]) {
          updateStatus(
            [latestStatus, APPLICATION_STATUS.Initial],
            app.Origin as Source,
            app?.[latestStatus]!,
            app?.[APPLICATION_STATUS.Initial]!,
          );
          latestStatus = APPLICATION_STATUS.Initial;
        }

        if (app?.[APPLICATION_STATUS.MgrTeam]) {
          updateStatus(
            [latestStatus, APPLICATION_STATUS.MgrTeam],
            app.Origin as Source,
            app?.[latestStatus]!,
            app?.[APPLICATION_STATUS.MgrTeam]!,
          );
          latestStatus = APPLICATION_STATUS.MgrTeam;
        }

        if (app?.[APPLICATION_STATUS.Technical] || app?.[APPLICATION_STATUS.Code]) {
          updateStatus(
            [latestStatus, APPLICATION_STATUS.Interviews],
            app.Origin as Source,
            app?.[latestStatus]!,
            app?.[APPLICATION_STATUS.Technical]!,
          );
          latestStatus = APPLICATION_STATUS.Code;
        }

        if (app?.[APPLICATION_STATUS.Offer]) {
          updateStatus(
            [latestStatus, APPLICATION_STATUS.Offer],
            app.Origin as Source,
            app?.[latestStatus]!,
            app?.[APPLICATION_STATUS.Offer]!,
          );
          latestStatus = APPLICATION_STATUS.Offer;
        }

        if (app?.Rejected || app?.Result === APPLICATION_STATUS.Rejected) {
          updateStatus(
            [latestStatus, APPLICATION_STATUS.Rejected],
            app.Origin as Source,
            app?.[latestStatus]!,
            app?.[APPLICATION_STATUS.Rejected]!,
          );
        } else if (app?.Result !== APPLICATION_STATUS.Accepted && app?.Result !== APPLICATION_STATUS.Offer) {
          updateStatus(
            [latestStatus, APPLICATION_STATUS.Waiting],
            app.Origin as Source,
            app?.[latestStatus]!,
            new Date(),
          )!;
        }
      });

    return data
      .sort((a, b) => {
        return statusesToShow.findIndex(s => s === a.start) - statusesToShow.findIndex(s => s === b.start)
      })
      .sort((a, b) => {
        return statusesToShow.findIndex(s => s === a.end) - statusesToShow.findIndex(s => s === b.end)
      });
    }, [applications, filterValue]);

  const maxValue = useMemo(() => {
    return statusDurations.reduce<number>((acc, s) => {
      acc = [acc, avg(s.times)].sort((a, b) => a - b).pop() ?? 0;
      return Math.ceil(acc / 5) * 5;
    }, 0);
  }, [statusDurations]);

  const xScale = useMemo(() => {
    return d3.scaleBand()
      .domain(statusDurations.map(s => s.status))
      .range([margin.Left, CHART_WIDTH - margin.Right]);
  }, [statusDurations])

  const yScale = useMemo(() => {
    return d3.scaleLinear()
      .domain([0, maxValue])
      .range([CHART_HEIGHT - margin.Bottom, margin.Top]);
  }, [maxValue]);

  const barWidth = ((CHART_WIDTH - (margin.Left + margin.Right)) / statusesToShow.length);

  useEffect(() => {
    if (!chartRef?.current || !statusDurations.length) return;

    const svg = d3.select(chartRef.current);

    if (!svg) return;

    const xAxis = svg.select('#axis-x');
    const yAxis = svg.select('#axis-y');

    if (xAxis) {
      xAxis
        .call(d3.axisBottom(xScale) as any);
    }
    if (yAxis) {
      yAxis
        .call(d3.axisLeft(yScale) as any);
    }
  }, [xScale, yScale, statusDurations]);

  const updateFilterValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value.replace('input-', '') as APPLICATION_STATUS);
  }, [setFilterValue]);

  return (
   <>
    <h2>How Long Stages Took</h2>
    <fieldset>
      <legend className="sr-only">Filters</legend>
      <div className="filters">
        {FILTER_VALUES.map((fv: APPLICATION_STATUS) => {
          const className = `filters__field${fv === filterValue ? ' active' : ''}`;
          return (
            <div key={fv} className={className}>
              <input type="radio" name="filter-timing" id={`input-${fv}`} checked={fv === filterValue} value={fv} onChange={updateFilterValue} />
              <label htmlFor={`input-${fv}`}>{fv}</label>
            </div>
          );
        })}
      </div>
    </fieldset>

    <SVG ref={chartRef} width={CHART_WIDTH} height={CHART_HEIGHT} shouldRender={!!applications}>
      <defs>
        {statusDurations.map((d: StatusDuration) => <Gradient key={`tg-${d.status}`} start={d.start} end={d.end} />)}
        {[APPLICATION_STATUS.Me, APPLICATION_STATUS.Recruiter, APPLICATION_STATUS.All].map((start: APPLICATION_STATUS) => <Gradient key={`tgs-${start}`} start={start} />)}
      </defs>
      <g id="axis-x" transform={`translate(0, ${CHART_HEIGHT - margin.Bottom})`} />
      <g id="axis-y" transform={`translate(${margin.Left}, 0)`} />
      <g className="data" transform={`translate(${margin.Right / 2}, ${margin.Top})`}>
        {statusDurations.map((d: StatusDuration, idx) => (
          <Bar
            key={d.status}
            width={barWidth}
            d={d}
            x={xScale(d.status) ?? 0}
            yScale={yScale}
            filter={filterValue}
          />
        ))}
      </g>
    </SVG>
   </>
  );
};

export default ApplicationsTiming;
