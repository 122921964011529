export enum APPLICATION_STATUS {
  Me = 'Me',
  Recruiter = 'Recruiter',
  Applied = 'Applied',
  Rejected = 'Rejected',
  Waiting = 'Waiting',
  Initial = 'Initial Interview',
  MgrTeam = 'Mgr/Team Interview',
  Technical = 'Technical Interview',
  Code = 'Code Challenge',
  Offer = 'Offer Sent',
  Interviews = 'Interviews',
  Accepted = 'Accepted',
  Declined = 'Declined',
  All = 'All'
}

export const APPLICATION_STATUS_LABELS: Record<string, string> = {
  [APPLICATION_STATUS.Me]: 'Self',
  [APPLICATION_STATUS.Initial]: 'Recruiter Screen',
  [APPLICATION_STATUS.MgrTeam]: 'Manager Screen',
  [APPLICATION_STATUS.Code]: 'Interviews',
  [APPLICATION_STATUS.Technical]: 'Interviews',
}

export const APPLICATION_STATUS_LABELS_SHORTENED: Record<string, string> = {

  [APPLICATION_STATUS.Applied]: 'Appl',
  [APPLICATION_STATUS.Initial]: 'Recr',
  [APPLICATION_STATUS.MgrTeam]: 'Mgr',
  [APPLICATION_STATUS.Code]: 'Intrv',
  [APPLICATION_STATUS.Technical]: 'Intrv',
  [APPLICATION_STATUS.Interviews]: 'Intrv',
  [APPLICATION_STATUS.Rejected]: 'Rjct',
  [APPLICATION_STATUS.Accepted]: 'Acc',
}

export type Source = APPLICATION_STATUS.Me | APPLICATION_STATUS.Recruiter;
